<template>
    <column>
        <token-detail
            v-if="testMint"
            :projectTitle="testMint.project.title + ' ' + `Test Mint`"
            :tokenId="testMint.id"
            :projectId="testMint.project.id"
            :projectAuthor="testMint.project.author"
            :authorWebsite="testMint.project.authorWebsite"
            dateMinted="January 2, 2022"
            mintPrice="0"
            :copyRight="testMint.project.copyRight"
            :imageSrc="testMint.thumbnailImageUrl"
            :imagePreviewUrl="testMint.previewImageUrl"
        >
        </token-detail>
    </column>
</template>

<script>
import Block from '@lkmx/flare/src/core/Block.vue';
import DoubleColumnLayout from '../layouts/DoubleColumnLayout.vue'
import TokenDetail from '../components/TokenDetail.vue';
import api from '@/service/api';

export default {
    name: 'test-mint-details',
    components: { Block, DoubleColumnLayout, TokenDetail },
    data() {
        return {
            testMint: null,
        }
    },
    async mounted () {
        await this.getTestMintById();
    },
    methods: {
      async getTestMintById() {
        let projectId = this.$route.params.projectId;
        let testId = this.$route.params.testId;
        this.testMint = await api.projects.tests.get(projectId, testId);
      },
    }
}
</script>

<style lang="scss" scoped>
</style>
